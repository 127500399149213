import "@browniebroke/gatsby-image-gallery/dist/style.css"

import SoftwareSideMenu, {
  MenuItemType,
  PageType,
} from "components/software-sidemenu"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Gallery from "@browniebroke/gatsby-image-gallery"
import Layout from "components/layout"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import SoftwareBreadcrumb from "components/software-sidemenu/breadcrumb"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

export default function EkranGoruntuleri({ data }) {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  const {
    genel: { ekranGoruntuleri },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title="Kütüphane Programı - Ekran Görüntüleri - Tusunami Kütüphane Takip"
        description="Tusunami Kütüphane Takip isimli kütüphane programımızın ekran görüntülerini inceleyebilir ve kütüphane programını indirmeden inceleyebilirsiniz!"
        pathname="https://www.kodvizit.com/kutuphane-programi/ekran-goruntuleri"
        datePublished="2017-02-09T22:22:55+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/kutuphane-programi",
            name: "Kütüphane Programı",
          },
        ]}
      />
      <Container className="mt-2 mb-5">
        <SoftwareBreadcrumb
          parentPage={PageType.Kutuphane}
          activeMenuItem={MenuItemType.EkranGoruntuleri}
        />
        <Row>
          <Col>
            <h1>{ekranGoruntuleri}</h1>
            <div style={{ cursor: "pointer" }}>
              <Gallery images={images} />
            </div>
          </Col>

          <Col md="3">
            <SoftwareSideMenu
              parentPage={PageType.Kutuphane}
              menuItems={[
                MenuItemType.Ozellikler,
                MenuItemType.SikSorulanSorular,
                MenuItemType.EkranGoruntuleri,
                MenuItemType.KullanimKilavuzu,
                MenuItemType.Referanslar,
              ]}
              activeMenuItem={MenuItemType.EkranGoruntuleri}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    allFile(
      filter: {
        relativePath: { regex: "/^kutuphane/ekran-goruntuleri/sergi/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid_withWebp
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

EkranGoruntuleri.propTypes = {
  data: PropTypes.object,
}
